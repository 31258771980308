.contact-container {
  width: 80%;
  height: auto;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: start;
  gap: 1rem;
  padding: 1rem 0;
}
.contact-container img {
  width: 50%;
  object-fit: contain;
}
.contact-container-form {
  width: 60%;
  display: flex;
  /* flex-wrap: wrap; */
  flex-direction: column;
  gap: 1rem;
  align-items: start;
  justify-content: start;
}
.contact-container-form h3 {
  width: 100%;
  text-align: center;
  font-size: 30px;
  font-weight: 200;
  margin-bottom: 10px;
}
.container-form-inner {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: start;
}
.container-form-inner h2 {
  font-size: 18px;
  font-weight: 400;
}
.container-form-inner p {
  margin-top: 5px;
  font-size: 14px;
  line-height: 1.7;

  color: grey;
}
.container-form-inner div {
  width: 35%;
}
.form {
  display: flex;
  align-items: start;
  flex-direction: column;
  gap: 10px;
}
.form input,textarea {
  border: 1px solid hsl(0, 0%, 82%);
  outline: none;
  padding:5px 10px;
  font-size: 15px;
  transition: all 300ms;
  border-radius: 10px;
}
.form input:focus,textarea:focus{
  border-color: black;
}
.form h3 {
  font-size: 25px;
  margin-bottom: 10px;
  font-weight: 500;
}
.form label {
  color: grey;
  font-size: 14px;
  margin-bottom: -5px;
}
textarea {
  border: 1px solid hsl(0, 0%, 82%);
  outline: none;
  font-size: 14px;
  resize: none;
  width: 100%;
  height: 5rem;

}
.contact-btn {
  font-size: 16px;
  padding: 10px 20px;
  border: 1px solid black;
  transition: all 300ms;
}
.contact-btn:hover {
  background-color: black;
  color: white;
}
@media (width <= 1100px) {
  .contact-container {
    align-items: center;
    flex-direction: column;
    height: auto;
    padding: 1rem 0;
  }
  .contact-container img {
    width: 50%;
  }
  .contact-container-form {
    width: 100%;
  }
}

@media (width <= 600px) {
  .contact-container img {
    width: 70%;
  }
}
