.loader{
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    display: grid;
    place-items: center;
    z-index: 99999;
    background-color: #3333336b;
}
