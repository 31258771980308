.cart {
  width: 0;
  height: 100vh;
  background-color: #5151516f;
  backdrop-filter: blur(20px);
  position: fixed;
  right: 0;
  top: 0;
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: start;
  gap: 1rem;
  z-index: 10;
  padding: 1rem;
  transform: translateX(5rem);
  box-shadow: -5px 0px 20px #a1a1a143;
  transition: all 400ms;
  color: white;
}
.cart p {
  margin-top: 2rem;
  font-weight: 100;
}
.isShowing {
  width: 20%;
  transform: none;
}
.cart-products-grid {
  width: 100%;
}
